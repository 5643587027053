var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"e9738066b01adfd84019f76a3d4aa4285ac82f9d"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

import { getEnvironmentString } from "@/libs/environment";

const environment = getEnvironmentString();

if (environment === "production" || environment === "staging") {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment,
    integrations: [
      new Sentry.Replay({ useCompression: false }),
      new Sentry.BrowserTracing(),
    ],
    beforeSend: (event) => {
      if (event.exception) {
        event.exception.values.forEach((exception) => {
          const index = exception.value.indexOf("in node:");
          if (index !== -1) {
            exception.value = exception.value.slice(0, index);
          }
        });
      }
      return event;
    },
    release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    tracesSampleRate: 1.0,
    ignoreErrors: [
      "_reportEvent is not defined",
      "internal server error",
      "Network Error",
      "network error",
      "Request failed with status code 400",
      "Request failed with status code 403",
      "Request failed with status code 500",
      "ResizeObserver loop completed with undelivered notifications",
      "ResizeObserver loop limit exceeded",
      "routeChange aborted",
    ],
  });
}
